import {ReactElement} from 'react';
import StyledSubmitButton from './styles';
import Props from './typings';

const SubmitButton = ({
  title,
  onClick,
  disabled = false,
  style = 'primary',
  maxWidth,
  children,
}: Props): ReactElement => {
  return (
    <StyledSubmitButton
      type="submit"
      aria-label={title ?? children}
      onClick={disabled ? undefined : onClick}
      aria-disabled={disabled}
      $style={style}
      $maxWidth={maxWidth}
    >
      {children}
    </StyledSubmitButton>
  );
};

export default SubmitButton;
